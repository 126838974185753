/** Variables */
//

// global navigation
const nav = document.getElementById('js-gnav'),
      smNav = document.getElementById('js-sm-nav'),
      burger = document.getElementById('js-burger'),
      openMenuClass = 'open-menu';
// メディアクエリ
const mediaQuery = window.matchMedia( "(max-width: 979px)" );


/** initialize */
document.addEventListener('DOMContentLoaded', function(){
  // burger class removed
  mediaQuery.addListener( burgerClassRemove );
  // burger
  //burgerMotion();
  // pagetop
  pagetop();
  // accordion
  accordion();
  // tab
  tab();
  // タップで電話
  telLink();

  // global navigation
  const cloneNav = nav.cloneNode(true);
  cloneNav.classList.add('sm-nav');
  smNav.appendChild(cloneNav);
  document.getElementsByClassName('sm-nav')[0].removeAttribute('id');
  // burger click event
  burger.addEventListener('click', function () {
    document.body.classList.toggle(openMenuClass);
    $('.sm-nav .global-menu').slideToggle( 200 );
  });
  // child
  const trigger = smNav.querySelectorAll('.trigger');
  for (let i = 0; i < trigger.length; i++){
    trigger[i].addEventListener('click', function(){
      $(this).next('ul.child').slideToggle( 400 );
    });
  };
});



// burger class removed
function burgerClassRemove( mq ){
  if( mq.matches ){
    return false;
  } else {
    // .slidenav-visible が付与しているかチェック
    const isClass = document.body.classList.contains(openMenuClass);
    if( isClass ){
      document.body.classList.remove(openMenuClass);
    }
  }
}



/**
***  burger  */
const burgerMotion = () => {
  if ( burger != null ) {
    burger.addEventListener('click', function () {
      document.body.classList.toggle(openMenuClass);
    });
  }
}



/**
***  pagetop  */
const pagetop = () => {
  const pagetop = document.getElementById('js-pagetop');
  const pagetopActiveClass = 'active';

  if ( pagetop != null ) {
    window.addEventListener('scroll', function (e) {
      if (window.scrollY > 100) {
        pagetop.classList.add(pagetopActiveClass);
      } else {
        pagetop.classList.remove(pagetopActiveClass);
      }
    })
    pagetop.addEventListener('click', function(){
      const begin = new Date() - 0;
      const yOffset = window.pageYOffset;
      const duration = 300;
      const timer = setInterval(function() {
        let current = new Date() - begin;
        if (current > duration) {
            clearInterval(timer);
            current = duration;
        };
        // スクロール位置を単位時間で変更する
        window.scrollTo(0, yOffset * (1 - current / duration))
      }, 10);
    }, false);
  }
}



/**
***  accordion  */
const accordion = () => {
  // Cross-browser way to get the computed height of a certain element.
  const getStyle = (el, styleProp) => {
    let value, defaultView = ( el.ownerDocument || document ).defaultView;
    // W3C standard way:
    if ( defaultView && defaultView.getComputedStyle ) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if ( el.currentStyle ) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
          var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = value || 0;
          value = el.style.pixelLeft + "px";
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return value;
        })(value);
      }
      return value;
    }
  }
  // アコーディオン処理
  const acc = document.getElementsByClassName('js-accordion-trigger');
  let i;
  // ページを開いた時にアコーディオンを開いた状態にしたいときの処理
  //const firstAccordion = acc[0];
  //const firstPanel = firstAccordion.nextElementSibling;
  //firstAccordion.classList.add('active');
  //firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

  // Add onclick listener to every accordion element
  for ( i = 0; i < acc.length; i++ ) {
    acc[i].addEventListener('click', function () {
      // For toggling purposes detect if the clicked section is already 'active'
      const isActive = this.classList.contains('active');
      // Close all accordions
      const allAccordions = document.getElementsByClassName('js-accordion-trigger');
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove('active');
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        }
      }
      // Toggle the clicked section using a ternary operator
      isActive ? this.classList.remove('active') : this.classList.add('active');
      // Toggle the panel element
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    })
  }
}



/**
***  tab  */
const tab = () => {
  const tabs = document.getElementsByClassName('js-tab')
  const allContents = document.getElementsByClassName('js-contents')
  const currentClassName = 'current'
  const currentViewClassName = 'current-view'
  //
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){

      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName);
        }
        this.classList.add(currentClassName);
      }

      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      }
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  }
}



// IE滅亡
const ieAlertClose = document.getElementById('ieAlertClose');
if( ieAlertClose ){
  const ieAlert = document.getElementById('ieAlert');
  ieAlertClose.addEventListener('click', function(e){
    ieAlert.classList.add('invisible');
  });
};



/** タップで電話 */
/** telLink */
const telLink = function() {
  const ua = navigator.userAgent;
  if( ua.match(/iPhone|Android.+Mobile/) ){
    return;
  } else {
    const telLink = document.querySelectorAll('.js-tel-link');
    if( telLink.length ){
      telLink.forEach( l => {
        l.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });
    }
  }
};

/* 保養所の個別ページのタイトルを半透明に */
$(window).on('load resize', function(){
  var winW = $(window).width();
  var devW = 980;
  if (winW <= devW) {
    //980px以下の時の処理
	$(function() {
		/* let target = $("#hoyouzyo_sticky").offset().top; */
		$(window).on("scroll", function() {
		let currentPos = $(window).scrollTop();
		if(currentPos > 56) {
		$("#hoyouzyo_sticky").addClass('head-opacity');
		} else{
		$("#hoyouzyo_sticky").removeClass('head-opacity');
		}
		});
	});
  } else {
    //980pxより大きい時の処理
	$(function() {
		/* let target = $("#hoyouzyo_sticky").offset().top; */
		$(window).on("scroll", function() {
		let currentPos = $(window).scrollTop();
		if(currentPos > 234) {
		$("#hoyouzyo_sticky").addClass('head-opacity');
		} else{
		$("#hoyouzyo_sticky").removeClass('head-opacity');
		}
		});
	});
  }
});
